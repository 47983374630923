import gsap from 'gsap'

const html = document.documentElement;
const { body } = document;
const isDebug = html.hasAttribute('data-debug');

const scroll = {
	y: 0,
	x: 0,
};

const mediaQueries = {
	sm: '(max-width: 767px)',
	md: '(min-width: 768px)',
	lg: '(min-width: 1024px)',
	xl: '(min-width: 1280px)',
};

const breakpoints = {
	sm: window.matchMedia(mediaQueries.sm),
	md: window.matchMedia(mediaQueries.md),
	lg: window.matchMedia(mediaQueries.lg),
	xl: window.matchMedia(mediaQueries.xl),
	// '2xl': window.matchMedia('(min-width: 1536px)'),
};

const production = 'production' === process.env.NODE_ENV;

const mm = gsap.matchMedia();

const getMobileOS = () => {
	const ua = navigator.userAgent;

	if (/android/i.test(ua)) {
		return 'android';
	}

	if (
		/iPad|iPhone|iPod/.test(ua) ||
		('MacIntel' === navigator.platform && 1 < navigator.maxTouchPoints)
	) {
		return 'ios';
	}

	return 'other';
};

const os = getMobileOS();

export { mm, mediaQueries, html, body, isDebug, scroll, breakpoints, production, os };

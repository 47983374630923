// /* global swag */
import modular from '@19h47/modular';
import { body, html, production, os } from 'utils/environment';
import Guid from 'vendors/Guid';

if (false === production) {
	const guid = new Guid();

	guid.init();
}

// eslint-disable-next-line new-cap
const app = new modular({ modules: [] });

function init() {
	app.init(app);

	html.classList.add('is-loaded');
	html.classList.add('is-ready');
	html.classList.add('is-first-load');
	html.classList.remove('is-loading');

	html.classList.remove('is-other');
	// eslint-disable-next-line no-nested-ternary
	html.classList.add('android' === os ? 'is-android' : 'ios' === os ? 'is-ios' : 'is-other');

	setTimeout(() => {
		body.classList.remove('js:cursor-wait');
		html.classList.add('has-dom-ready');
	}, 0);
}

window.onload = async () => {
	// const $style = document.getElementById(`${swag.text_domain}-main-css`);

	// if ($style) {
	// 	if ($style.isLoaded) {
	// 		init();
	// 	} else {
	// 		$style.addEventListener('load', () => init());
	// 	}
	// } else {
	// 	console.warn(`The "${swag.text_domain}-main-css" stylesheet not found`);
	// }
	init();
};
